import React from "react"

import Band from "../../components/band"
import Layout from "../../components/layout"

import dcLetter from "../../assets/files/CareFirst CHPDC Substitute Notice.pdf"

const LetterDC = () => (
  <Layout header="Didn’t Receive a Letter Regarding the Cyberattack?">
    <Band>
      <p>
        The information of anyone who has been an enrollee of CHPDC is impacted by the incident.
        Current and former employees of CHPDC are impacted.
      </p>
      <p>
        If you did not receive a notification letter and you were an enrollee in CHPDC, 
        formerly Trusted Health Plan, please <a href={dcLetter} target="_blank" rel="noreferrer">click here</a>.
      </p>
    </Band>
  </Layout>
)

export default LetterDC
